declare global {
  // eslint-disable-next-line no-unused-vars
  interface Date {
    fullDate: (_props?: { withShortMonth?: boolean }) => string;
    YYYYMMDD: () => string;
    toLocaleTime: () => string;
  }
}

Date.prototype.fullDate = function (props?: { withShortMonth?: boolean }) {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: props?.withShortMonth ? 'short' : 'long',
    year: 'numeric',
    timeZone: 'Asia/Jakarta',
  };
  return this.toLocaleDateString('id-ID', options);
};

Date.prototype.YYYYMMDD = function () {
  return `${this.getFullYear().toString().padStart(4, '0')}-${(this.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${this.getDate().toString().padStart(2, '0')}`;
};

Date.prototype.toLocaleTime = function () {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Asia/Jakarta',
  };

  return this.toLocaleTimeString('id-ID', options);
};

export {};
