const Illustrations = {
  empty: '/assets/illustrations/empty.svg',
  emptyMutasiSakuAnak: '/assets/illustrations/empty-mutasi-saku-anak.svg',
  emptyFilterSakuAnak: '/assets/illustrations/empty-filter-mutasi-saku-anak.svg',
  viewSaku: '/assets/illustrations/illustration-view-saku.svg',
  registerQris: '/assets/illustrations/illustration-register-qris.svg',
  phone: '/assets/illustrations/phone.svg',
  illustrationOnboarding: '/assets/illustrations/illustration-onboarding.svg',
  illustrationOnboardingHeadline: '/assets/illustrations/illustration-onboarding-headline.svg',
  illustrationInfoSaku: '/assets/illustrations/illustration-info-saku.svg',
  illustrationLimitSakuBisnis: '/assets/illustrations/illustrastion-limit-saku-bisnis.png',
  illustrationEmptyTransactions: '/assets/illustrations/illustration-empty-transactions.svg',
  illustrationEmptyTransferMethod: '/assets/illustrations/illustration-empty-transfer-method.svg',
  illustrationEmptySaldo: '/assets/illustrations/illustration-empty-saldo.svg',
  illustrationMaksAccount: '/assets/illustrations/illustration-maks-account.svg',
  illustrationMinAccount: '/assets/illustrations/illustration-min-account.svg',
  illustrationMassLimit: '/assets/illustrations/illustration-mass-transfer-limit.svg',
  generalLimit: '/assets/illustrations/general-limit.svg',
  incomingFeature: '/assets/illustrations/incoming-feature.svg',
  illustrationGeneralError: '/assets/illustrations/client-error.webp',
  illustrationBannerEmployee: '/assets/illustrations/illustration-banner-employee.svg',
  illustrationIconEmployee: '/assets/illustrations/illustration-ic-access-employee.webp',

  //Qris Acquirer Activaion
  illustrationQrisOnboarding: '/assets/illustrations/illustration-qris-onboarding.svg',
  illustrationBenefit1: '/assets/illustrations/illustration-benefit-1.svg',
  illustrationBenefit2: '/assets/illustrations/illustration-benefit-2.svg',
  illustrationBenefit3: '/assets/illustrations/illustration-benefit-3.svg',
  illustrationQuestionMark: '/assets/illustrations/illustration-question-mark.svg',
  illustrationAddressLocation: '/assets/illustrations/illustration-address-location.svg',
  illustrationCamera: '/assets/illustrations/camera.svg',
  illustrationGallery: '/assets/illustrations/gallery.svg',
  illustrationQrisBeranda: '/assets/illustrations/illustration-qris-beranda.svg',
  illustrationInfoSaldoQris: '/assets/illustrations/illustration-information-saldo-qris.svg',
  illustrationQrisBalanceInfo: '/assets/illustrations/illustration-balance-info.svg',

  //Raya Lottery
  illustrationGagalLoad: '/assets/illustrations/illustration-gagal-load.svg',
};

export default Illustrations;
