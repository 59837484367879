import 'antd/dist/antd.css';
import '../styles/globals.css';
import '../styles/custom-fonts.css';
import '../styles/custom-animations.css';
import '../styles/skeleton.css';
import '../styles/custom-elevations.css';
import '../styles/picker.css';
import 'intro.js/introjs.css';
import 'utils/dateExtension';
import type { AppProps } from 'next/app';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from '../lib/queryClient';
import Head from 'next/head';
import { Provider } from 'react-redux';
import store, { persistor } from '../redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { emitter } from 'lib/emitter';
import { Emitter_Keys } from 'data/constants/eventEmitterKeys';
import { ErrorProps } from 'redux/slices/appSlice';
import Common from 'data/constants/common';
import ImagesBase64 from 'data/constants/imageBase64';
import LoadingDialog from 'components/DialogLoading';
import { PathRayaLottery } from 'data/constants/path';
import ErrorBoundary from 'components/ErrorBoundary';
import useAppWindow from 'hooks/useAppWindow';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const [isLoadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    const handleStart = (url: string) => {
      if ([PathRayaLottery.home, PathRayaLottery.howToGet, PathRayaLottery.history].includes(url)) {
        setLoadingPage(true);
      }
    };
    const handleComplete = () => {
      setLoadingPage(false);
    };

    const handleError = () => {
      setLoadingPage(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  }, [router]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (!navigator.onLine) {
        const errorProps: ErrorProps = {
          isError: true,
          title: Common.lostConnectionTitle,
          description: Common.lostConnectionDescription,
          image: ImagesBase64.lostConnection,
        };
        emitter.emit(Emitter_Keys.SHOW_GLOBAL_ERROR, errorProps);

        throw Error(`routeChange aborted, because the browser was offline`);
      }
    });

    return () => {
      router.events.off('routeChangeStart', () => {
        console.log('unsubscribed');
      });
    };
  }, []);

  const appWindow = useAppWindow();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <meta name="HandheldFriendly" content="true" />
          </Head>
          <ErrorBoundary appWindow={appWindow}>
            <Component {...pageProps} />
          </ErrorBoundary>
          <LoadingDialog loading={isLoadingPage} />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default MyApp;
