import { AnyAction, configureStore } from '@reduxjs/toolkit';
import transferReducer from './slices/transferSlice';
import massTransferReducer from './slices/massTransferSlice';
import qrisTransactionHistoryReducer, {
  QrisTransactionHistoryState,
} from './slices/qrisTransactionHistorySlice';
import qrisTransactionHistoryFilterReducer from './slices/qrisTransactionHistoryFilterSlice';
import userReducer, { UserState } from './slices/user';
import appReducer from './slices/appSlice';
import imgResultReducer from './slices/imgResultSlice';
import sakuReducer from './slices/sakuSlice';
import lotteryReducer from './slices/lotterySlice';
import sakuAnakReducer, { ISakuAnakState } from './slices/sakuAnakSlice';
import qrisTransactionReducer from './slices/qrisTransactionSlice';
import qrisActivation from './slices/qrisActivation';
import storageSession from 'redux-persist/lib/storage/session';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import CryptoJS from 'crypto-js';
import historyLotteryFilterSlice from './slices/historyLotteryFilterSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Encrypt
const encrypt = createTransform(
  (inboundState) => {
    if (!inboundState) return inboundState;
    const cryptedText = CryptoJS.AES.encrypt(JSON.stringify(inboundState), '6C71bucmKu');

    return cryptedText.toString();
  },
  (outboundState: any) => {
    if (!outboundState) return outboundState;
    const bytes = CryptoJS.AES.decrypt(outboundState, '6C71bucmKu');
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }
);

// Transfer Persistent Storage (Saku Bisnis)
const transferPersistConfig = {
  key: 'transfer',
  storage: storageSession,
  transforms: [encrypt],
};

const transferPersistReducer = persistReducer(transferPersistConfig, transferReducer);

// Mass Transfer Persistent Storage (Saku Bisnis)
const massTransferPersistConfig = {
  key: 'massTransfer',
  storage: storageSession,
  transforms: [encrypt],
};

const massTransferPersistReducer = persistReducer(massTransferPersistConfig, massTransferReducer);

// Saku Anak Persistent Storage (Saku Anak)
const sakuAnakPersistConfig = {
  key: 'sakuAnak',
  storage: storageSession,
  transforms: [encrypt],
};

const qrisTransactionHistoryPersistConfig = {
  key: 'qrisTransactionHistoryDetail',
  storage: storageSession,
  transforms: [encrypt],
};

const qrisTransactionHistoryFilterPersistConfig = {
  key: 'qrisTransactionHistoryFilter',
  storage: storageSession,
  transforms: [encrypt],
};

const qrisTransactionHistoryPersistReducer = persistReducer<QrisTransactionHistoryState, AnyAction>(
  qrisTransactionHistoryPersistConfig,
  qrisTransactionHistoryReducer
);

const qrisTransactionHistoryFilterPersistReducer = persistReducer(
  qrisTransactionHistoryFilterPersistConfig,
  qrisTransactionHistoryFilterReducer
);

const sakuAnakPersistReducer = persistReducer<ISakuAnakState, AnyAction>(
  sakuAnakPersistConfig,
  sakuAnakReducer
);

const userPersistConfig = {
  key: 'user',
  storage: storageSession,
  transforms: [encrypt],
};

const userPersistReducer = persistReducer<UserState, AnyAction>(userPersistConfig, userReducer);

// Store

const store = configureStore({
  reducer: {
    transfer: transferPersistReducer,
    massTransfer: massTransferPersistReducer,
    user: userPersistReducer,
    app: appReducer,
    saku: sakuReducer,
    sakuAnak: sakuAnakPersistReducer,
    qrisActivation: qrisActivation,
    qrisTransactionHistory: qrisTransactionReducer,
    qrisTransactionHistoryDetail: qrisTransactionHistoryPersistReducer,
    qrisTransactionHistoryFilter: qrisTransactionHistoryFilterPersistReducer,
    imgResult: imgResultReducer,
    historyLotteryFilter: historyLotteryFilterSlice,
    lottery: lotteryReducer,
  },
  devTools: process.env.APP_CONFIG_ENV === 'development',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Infer persistent store from the store itself
export const persistor = persistStore(store);

// Infer the type of the `useAppSelector` hook and `useAppDispatch` hook
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
