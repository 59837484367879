import Illustrations from 'data/constants/illustrations';
import React, { ReactNode } from 'react';
import Image from 'next/image';
import { HandleWebviewCallback } from 'utils/handleWebviewCallback';

interface ErrorBoundaryProps {
  children: ReactNode;
  appWindow: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  handleBackToBerandaRaya = () => {
    HandleWebviewCallback.closeWebviewWithoutConfirmation(this.props.appWindow);
  };

  componentDidUpdate(
    _: Readonly<ErrorBoundaryProps>,
    prevState: Readonly<ErrorBoundaryState>,
    __?: any
  ): void {
    if (prevState.hasError) {
      this.setState({
        hasError: false,
        error: null,
      });
    }
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div className="h-screen">
          <div className="flex flex-col items-center justify-center max-w-screen h-screen p-6">
            <Image
              priority
              src={Illustrations.illustrationGeneralError}
              height={150}
              width={250}
              alt="Error Illustration"
            />
            <h3 className="text-xl font-medium mt-2">Mohon maaf ya, Kawan</h3>
            <h3 className="text-secondary font-medium text-center mt-4">
              Tenang tim kami sedang turun tangan. Kamu bisa coba beberapa saat lagi
            </h3>

            <div className="mt-6 p-4 bg-gray-200 w-full text-secondary text-sm rounded-md overflow-y-auto">
              {error?.toString()}
            </div>

            <div className="mt-6">
              <button className="custom-button font-bold" type="button" onClick={this.handleBackToBerandaRaya}>
                Kembali ke Beranda
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
