import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Icons from 'data/constants/icons';
import { UseQrisCategory } from 'hooks/useQrisCategory';
import { QRISDetailResponse } from 'types/dto/qrisDetailResponse';
interface ShopType {
  icon: string;
  name: string;
  type: string;
  value: boolean;
}

export interface Photo {
  onlineStore?: string | null;
  offlineStore?: string | null;
}

export interface GPSQRISActivation {
  businessGpsLong?: string | null;
  businessGpsLat?: string | null;
  businessGpsCity?: string | null;
  businessGpsProvince?: string | null;
}

export interface QrisActivationData {
  data: {
    id: string;
    qrisName: string;
    qrisEmail: string;
    qrisSaku: string;
    address: string;
    province: string;
    city: string;
    district: string;
    subDistrict: string;
    subDistrictId?: number;
    detailAddress: string;
    onlineShop: ShopType;
    offlineShop: ShopType;
    shopIncome: string;
    categories: UseQrisCategory[];
    categoryName: string;
    photo?: Photo | null;
    location?: GPSQRISActivation | null;
    qrisSakuId: string;
    isFilled: boolean;
    rejectReason: string[];
    SakuRaya: {
      Id: number;
      UserId: number;
      Name: string;
      CoreCode: string;
      SavingType: number;
      TotalTransaction: number;
      Balance: number;
      DefaultSavingType: number;
      TargetBalance: number;
      TargetDate: string;
      StartDate: string;
      Status: string;
      AutoDebet: false;
      AutoDebetFreq: string;
      AutoDebetFreqD: number;
      AutoDebetNominal: number;
      CreateDate: string;
      UpdateDate: string;
    };
  };
}

export interface Params {
  isEnabled: boolean;
  index: number;
}

const QrisActivationSlice = createSlice({
  name: 'QrisActivation',
  initialState: {
    data: {
      qrisName: '',
      qrisEmail: '',
      qrisSaku: '',
      shopIncome: '',
      address: '',
      province: '',
      city: '',
      district: '',
      subDistrict: '',
      subDistrictId: 0,
      detailAddress: '',
      onlineShop: {
        icon: Icons.icOnlineShop,
        name: 'Online',
        type: 'E-commerce, Instagram, WhatsApp',
        value: false,
      },
      offlineShop: {
        icon: Icons.icOfflineShop,
        name: 'Offline',
        type: 'Outlet, ruko, gerobak',
        value: false,
      },
      categories: [] as UseQrisCategory[],
      categoryName: '',
      photo: null,
      location: null,
      isFilled: false,
      rejectReason: [] as string[],
    },
  } as QrisActivationData,

  reducers: {
    setQrisId: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        id: action.payload,
      };
    },
    setQrisName: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        qrisName: action.payload,
      };
    },
    setQrisEmail: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        qrisEmail: action.payload,
      };
    },
    setQrisSaku: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        qrisSaku: action.payload,
      };
    },
    onCheckedOnline: (state, action: PayloadAction<boolean>) => {
      state.data = {
        ...state.data,
        onlineShop: {
          ...state.data.onlineShop,
          value: action.payload,
        },
      };
    },
    onCheckedOffline: (state, action: PayloadAction<boolean>) => {
      state.data = {
        ...state.data,
        offlineShop: {
          ...state.data.offlineShop,
          value: action.payload,
        },
      };
    },
    onCheckedCategory: (state, action: PayloadAction<Params>) => {
      state.data = {
        ...state.data,
        categories: state.data.categories.map((item, index) => {
          if (index == action.payload.index) {
            item.isEnabled = action.payload.isEnabled;
            return item;
          } else {
            item.isEnabled = false;
            return item;
          }
        }),
      };
    },
    setShopAdress: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        qrisEmail: action.payload,
      };
    },
    setShopIncome: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        shopIncome: action.payload,
      };
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        address: action.payload,
      };
    },
    setProvince: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        province: action.payload,
      };
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        city: action.payload,
      };
    },
    setDistrict: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        district: action.payload,
      };
    },
    setSubDistrict: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        subDistrict: action.payload,
      };
    },
    setDetailAddress: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        detailAddress: action.payload,
      };
    },
    setPhotoOnline: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        photo: {
          ...state.data.photo,
          onlineStore: action.payload,
        },
      };
    },
    setPhotoOffline: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        photo: {
          ...state.data.photo,
          offlineStore: action.payload,
        },
      };
    },
    setCategories: (state, action: PayloadAction<UseQrisCategory[]>) => {
      let newList: any = [];
      action.payload.forEach((element) => {
        if (element.name == state.data.categoryName) {
          element.isEnabled = true;
        } else {
          element.isEnabled = false;
        }
        newList.push(element);
      });

      state.data = {
        ...state.data,
        categories: newList,
      };
    },
    removePhotoQrisActivation: (state, action: PayloadAction<any>) => {
      if (action.payload.isOnlineStore) {
        state.data = {
          ...state.data,
          photo: {
            ...state.data.photo,
            onlineStore: null,
          },
        };
      } else if (action.payload.isOfflineStore) {
        state.data = {
          ...state.data,
          photo: {
            ...state.data.photo,
            offlineStore: null,
          },
        };
      }
    },
    setAllData: (state, action: PayloadAction<QRISDetailResponse>) => {
      state.data = {
        id: action.payload.Id.toString(),
        qrisName: action.payload.QrisName,
        qrisEmail: action.payload.BusinessEmail,
        qrisSaku: action.payload.SakuRaya.CoreCode.toString(),
        qrisSakuId: action.payload.SakuRayaId.toString(),
        address: `${action.payload.Province}, ${action.payload.City}, ${action.payload.District}, ${action.payload.SubDistrict}`,
        province: action.payload.Province,
        city: action.payload.City,
        district: action.payload.District,
        subDistrict: action.payload.SubDistrict,
        detailAddress: action.payload.Address,
        SakuRaya: action.payload.SakuRaya,
        onlineShop: {
          ...state.data.onlineShop,
          value: action.payload.TypeOfBusiness.includes('online'),
        },
        offlineShop: {
          ...state.data.offlineShop,
          value: action.payload.TypeOfBusiness.includes('offline'),
        },
        shopIncome: action.payload.Omset,
        categoryName: action.payload.Category,
        categories: state.data.categories,
        photo: {
          onlineStore: action.payload.PhotoOnlineStoreUrl,
          offlineStore: action.payload.PhotoBusinessUrl,
        },
        location: {
          businessGpsLong: action.payload.BusinessGpsLong,
          businessGpsLat: action.payload.BusinessGpsLat,
          businessGpsCity: action.payload.BusinessGpsCity,
          businessGpsProvince: action.payload.BusinessGpsProvince,
        },
        isFilled: true,
        rejectReason: action.payload.RejectionReasons,
        subDistrictId: action.payload.SubDistrictId,
      };
    },
    setCategoryName: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        categoryName: action.payload,
      };
    },
    setSakuId: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        qrisSakuId: action.payload,
      };
    },
    setIsFilled: (state, action: PayloadAction<boolean>) => {
      state.data = {
        ...state.data,
        isFilled: action.payload,
      };
    },
    setRejectReason: (state, action: PayloadAction<string[]>) => {
      state.data = {
        ...state.data,
        rejectReason: action.payload,
      };
    },
    setSubDistrictId: (state, action: PayloadAction<number>) => {
      state.data = {
        ...state.data,
        subDistrictId: action.payload,
      };
    },
  },
});

export const {
  setQrisId,
  onCheckedOnline,
  onCheckedOffline,
  onCheckedCategory,
  setQrisEmail,
  setQrisName,
  setQrisSaku,
  setShopIncome,
  setPhotoOffline,
  setPhotoOnline,
  removePhotoQrisActivation,
  setAddress,
  setProvince,
  setCity,
  setDistrict,
  setSubDistrict,
  setDetailAddress,
  setCategories,
  setAllData,
  setCategoryName,
  setSakuId: setSakuId,
  setIsFilled: setIsFilled,
  setSubDistrictId,
} = QrisActivationSlice.actions;
export default QrisActivationSlice.reducer;
