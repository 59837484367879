import { useEffect, useRef } from 'react';

const useAppWindow = () => {
  var appWindow: any = useRef(null);

  useEffect(() => {
    appWindow.current = window;
  }, []);

  return appWindow;
};

export default useAppWindow;
